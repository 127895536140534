import { fromJS } from "immutable";
import { loadState } from "../../core/utils/localStorage";

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  TOKEN_FAILURE,
  RENEW_REQUEST,
  RENEW_SUCCESS,
  RENEW_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_FAILURE,
  LOAD_USER_REQUEST_FAILURE,
  LOAD_USER_REQUEST_SUCCESS,
  GET_NOTIFICATIONS_REQUEST_SUCCESS,
  GET_NOTIFICATIONS_REQUEST_FAILURE,
  TOGGLE_SIDEBAR_LAYOUT,
  WIDGET_REQUEST_SUCCESS,
  WIDGET_REQUEST_FAILURE,
} from "./constants";

const persistedState = loadState() || {};
// The initial state of the App
export const initialState = fromJS({
  isFetching: false,
  token: persistedState.token,
  user: null,
  error: null,
  widgets: null,
  notifications: [],
  sidebar: true,
});

function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return state.set("isFetching", true).set("error", false);
    case LOGIN_SUCCESS:
      return state
        .set("isFetching", false)
        .set("token", action.payload.access_token)
        .set("error", false);

    case LOGIN_FAILURE:
      return state
        .set("isFetching", false)
        .set("token", null)
        .set("error", "Username or Password is invalid");
    
    case TOKEN_FAILURE:
      return state
        .set("isFetching", false)
        .set("token", null)
        .set("error", "Multi-Factor token is invalid");

    case RENEW_REQUEST:
      return state.set("isFetching", true).set("error", false);
    case RENEW_SUCCESS:
      return state
        .set("isFetching", false)
        .set("token", action.payload.access_token)
        .set("error", false);

    case RENEW_FAILURE:
      return state
        .set("isFetching", false)
        .set("token", null)
        .set("error", "Unable to renew session");

    case LOGOUT_REQUEST:
      return state
        .set("isFetching", false)
        .set("token", null)
        .set("user", null)
        .set("notifications", null)
        .set("error", false);

    case LOGOUT_FAILURE:
      return state.set("isFetching", false);

    case LOAD_USER_REQUEST_SUCCESS:
      return state.set("isFetching", false).set("user", action.payload);

    case LOAD_USER_REQUEST_FAILURE:
      return state.set("isFetching", false).set("user", null);

    case WIDGET_REQUEST_SUCCESS:
      return state.set("isFetching", false).set("widgets", action.payload);

    case WIDGET_REQUEST_FAILURE:
      return state.set("isFetching", false).set("widgets", null);

    case GET_NOTIFICATIONS_REQUEST_SUCCESS:
      return state
        .set("isFetching", false)
        .set("notifications", action.payload);

    case GET_NOTIFICATIONS_REQUEST_FAILURE:
      return state.set("isFetching", false).set("notifications", null);
    case TOGGLE_SIDEBAR_LAYOUT:
      return state.set("sidebar", action.payload);

    default:
      return state;
  }
}

export default authReducer;
