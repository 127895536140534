import {
  LOGIN_REQUEST,
  RENEW_REQUEST,
  LOGOUT_REQUEST,
  LOAD_USER_REQUEST,
  GET_NOTIFICATIONS_REQUEST,
  TOGGLE_SIDEBAR_LAYOUT,
  LAYOUT_REQUEST,
} from "./constants";

export const doLogin = ({ username, secret, token }, callback = () => { }) => ({
  type: LOGIN_REQUEST,
  payload: { username, secret, token },
  callback,
});

export const doRenew = (callback = () => {}) => ({
  type: RENEW_REQUEST,
  callback,
});

export const doLogout = (callback = () => {}) => ({
  type: LOGOUT_REQUEST,
  callback,
});

export const loadUser = (callback = () => {}) => ({
  type: LOAD_USER_REQUEST,
  callback,
});

export const loadNotifications = (callback = () => {}) => ({
  type: GET_NOTIFICATIONS_REQUEST,
  callback,
});

export const renew = (callback = () => {}) => ({
  type: RENEW_REQUEST,
  callback,
});

export const saveLayout = (payload) => ({
  type: LAYOUT_REQUEST,
  payload,
});

export const toggleSidebar = (shown) => ({
  type: TOGGLE_SIDEBAR_LAYOUT,
  payload: shown,
});
