import { connect } from "react-redux";
import { compose } from "redux";
import logo from "../../Assets/images/auth/SiON_Logo.png";
// import injectReducer from '../../utils/injectReducer';
import injectSaga from "../../core/store/injectSaga";
import { doLogin } from "../App/actions";
// import reducer from './reducer';
import saga from "./saga";
import {
  Popover,
  Tooltip,
  OverlayTrigger,
  Button,
  Form,
} from "react-bootstrap";
import React, { useState, useCallback } from "react";
import Api from "../../core/Api/index";
import axios from "axios";
import { Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
// const renderTooltip = (props) => (
//   <Tooltip id="button-tooltip" {...props}>
//     You may enable Multi-Factor Authentication within Settings
//   </Tooltip>
// );

// class LoginPage extends Component {
//   state = {};
//   componentDidMount() {}
//   onLogin = (e) => {
//     e.preventDefault();
//     this.setState({ submitting: true });
//     const { username, secret, token } = this.state;
//     // {username: 'Calibrate', secret: 'Security!2019'}
//     this.props.requestLogin({ username, secret, token }, (success) => {
//       this.setState({ submitting: false });
//       if (!success) {
//         this.setState({ errorMessage: "Please check your login credentials and try again." });
//       }
//     });
//   };

//   handleChangeInput = (e) => {
//     const { name, value } = e.currentTarget;
//     this.setState({ [name]: value });
//   };

//   render() {
//     return (
//       <div className="container-scroller">
//         <div className="container-fluid page-body-wrapper full-page-wrapper">
//           <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
//             <div className="row flex-grow">
//               <div className="col-lg-6 login-half-bg">
//                 <div className="login-half-text">
//                   <img src={logo} alt="Source not found" />
//                 </div>
//               </div>
//               <div className="col-lg-6 d-flex align-items-center justify-content-center">
//                 <div className="auth-form-transparent text-center p-3">
//                   <h4>
//                     Welcome to <img src={logo} alt="Source not found" />{" "}
//                   </h4>
//                   {/* <h6 className="font-weight-light">
//                     Login to your SION account
//                   </h6> */}
//                   <form className="pt-3 text-left">
//                     <div className="form-group">
//                       <label htmlFor="exampleInputEmail">Username</label>
//                       <div className="input-group">
//                         <input
//                           name="username"
//                           type="text"
//                           className="form-control form-control-lg border-left-0"
//                           id="exampleInputEmail"
//                           placeholder="Enter Your Username"
//                           onChange={this.handleChangeInput}
//                         />
//                         <div className="input-group-prepend bg-transparent">
//                           <span className="input-group-text bg-transparent border-right-0">
//                             <i className="mdi mdi-account-outline"></i>
//                           </span>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="form-group">
//                       <label htmlFor="exampleInputPassword">Password</label>
//                       <div className="input-group">
//                         <input
//                           type="password"
//                           name="secret"
//                           className="form-control form-control-lg border-left-0"
//                           id="exampleInputPassword"
//                           placeholder="Enter Your Password"
//                           onChange={this.handleChangeInput}
//                         />
//                         <div className="input-group-prepend bg-transparent">
//                           <span className="input-group-text bg-transparent border-right-0">
//                             <i className="mdi mdi-lock-outline"></i>
//                           </span>
//                         </div>
//                       </div>
//                     </div>

//                     <div className="form-group mt-3">
//                       <label htmlFor="exampleInputMFA">
//                         Multi-Factor Token
//                       </label>
//                       <OverlayTrigger
//                         placement="right"
//                         delay={{ show: 250, hide: 400 }}
//                         overlay={renderTooltip}
//                       >
//                         <i className="mdi mdi-information-outline ml-3"></i>
//                       </OverlayTrigger>
//                       <div className="input-group">
//                         <input
//                           type="number"
//                           size="6"
//                           name="token"
//                           className="form-control form-control-lg border-left-0"
//                           id="exampleInputMFA"
//                           placeholder="Enter Your Multi-Factor Authentication Token (Optional)"
//                           onChange={this.handleChangeInput}
//                         />
//                         <div className="input-group-prepend bg-transparent">
//                           <span className="input-group-text bg-transparent border-right-0">
//                             <i className="mdi mdi-key-outline"></i>
//                           </span>
//                         </div>
//                       </div>
//                     </div>

//                     {this.state.errorMessage && (
//                       <div className="text-danger text-center">
//                         <br />
//                         {this.state.errorMessage}
//                       </div>
//                     )}
//                     <div className="my-2 d-flex align-items-center justify-content-center mt-5">
//                       <button
//                         className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
//                         onClick={this.onLogin}
//                         disabled={this.state.submitting}
//                       >
//                         LOGIN
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

function LoginPage(props) {
  const [username, setUsername] = useState("");
  const [secret, setSecret] = useState("");
  const [token, setToken] = useState("");
  const [submtting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [show, setShow] = useState(false);
  const [otp, setotp] = useState("");

  const handleClose = () => {
    setShow(false);
    setotp("");
  };
  const handleShow = () => setShow(true);

  const handleOnChange = useCallback((event) => {
    setUsername(event.target.value);
  });

  const handleOnChangePassword = useCallback((event) => {
    event.preventDefault();
    console.log(event.target.value);
    setSecret(event.target.value);
  });

  function handleOTPSubmit(event) {
    event.preventDefault();
    console.log(otp);
  }

  const handleOTPChange = () => {
    setShow(false);
    const token = otp
    console.log(username, secret, token)
    props.requestLogin({ username, secret, token }, (success) => {
      setSubmitting(false);
      if (!success) {
        setErrorMessage("Invalid token. Please try again.");
      }
    });
  };

  function isMFAEnabled() {
    const ep = `${Api.request.ROOT_API_URL}/ismfaenabled/`;
    axios
      .post(
        ep,
        JSON.stringify({
          username: username,
        })
      )
      .then((res) => {
        if (res.data === true) {
          setShow(true);
        } else {
          console.log("false");
          props.requestLogin({ username, secret, token }, (success) => {
            setSubmitting(false);
            if (!success) {
              setErrorMessage(
                "Invalid username or password. Please try again."
              );
            }
          });
        }
      })
      .catch((err) => console.log(err));
    //do something
  }

  return (
    <section class="vh-100 gradient-custom">
      <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-12 col-md-8 col-lg-6 col-xl-5">
            <div
              class="card  text-black"
              style={{ borderRadius: "1rem" }}
            >
              <div class="card-body p-5 text-center">
                <div class="mb-md-5 mt-md-4 pb-5">
                  <h2 class="fw-bold mb-2 text-uppercase text-gray-50">
                    Welcome to <img src={logo} alt="Source not found" />{" "}
                  </h2>
                  <p class="text-gray-50 mb-5">
                    Detect, Predict, and Prevent Insider Threats
                  </p>

                  <div class="form-outline form-white mb-4">
                    <input
                      type="email"
                      id="typeEmailX"
                      class="form-control form-control-lg"
                      placeholder="Enter your username"
                      onChange={handleOnChange}
                    />
                  </div>

                  <div class="form-outline form-white mb-4">
                    <input
                      type="password"
                      id="typePasswordX"
                      class="form-control form-control-lg"
                      placeholder="Enter your password"
                      onChange={handleOnChangePassword}
                    />
                  </div>
                  <button
                    class="btn btn-primary btn-lg px-5"
                    type="submit"
                    onClick={isMFAEnabled}
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter Multi-Factor Authentication Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please enter the Multi-Factor Authentication code from your mobile
          device.
          <OtpInput
            value={otp}
            onChange={setotp}
            numInputs={6}
            isInputNum={true}
            separator={
              <span>
                <strong>.</strong>
              </span>
            }
            inputStyle={{
              width: "2.5rem",
              height: "2.5rem",
              margin: "1rem 1rem",
              fontSize: "2rem",
              borderRadius: 4,
              border: "1px solid rgba(10, 174, 227, 100)",
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleOTPChange}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}

const mapStateToProps = ({ auth }) => {
  return {};
};
const mapDispatchToProps = (dispatch) => ({
  requestLogin: (evt, cb) => dispatch(doLogin(evt, cb)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// const withReducer = injectReducer({ key: 'auth', reducer });
const withSaga = injectSaga({ key: "auth", saga });

export default compose(
  // withReducer,
  withSaga,
  withConnect
)(LoginPage);
