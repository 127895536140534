/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */
export const REAL_TIME_INTERVALS = 300000;
export const LOGIN_REQUEST = "LOGIN/REQUEST";
export const LOGIN_SUCCESS = "LOGIN/SUCCESS";
export const LOGIN_FAILURE = "LOGIN/FAILURE";
export const TOKEN_FAILURE = "LOGIN/TOKEN_FAILURE"

export const RENEW_REQUEST = "RENEW/REQUEST";
export const RENEW_SUCCESS = "RENEW/SUCCESS";
export const RENEW_FAILURE = "RENEW/FAILURE";

export const LAYOUT_REQUEST = "LAYOUT/REQUEST";
export const LAYOUT_SUCCESS = "LAYOUT/SUCCESS";
export const LAYOUT_FAILURE = "LAYOUT/FAILURE";

export const LOGOUT_REQUEST = "LOGOUT/REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT/SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT/FAILURE";

export const WIDGET_REQUEST = "WIDGET_REQUEST";
export const WIDGET_REQUEST_SUCCESS = "WIDGET_REQUEST/SUCCESS";
export const WIDGET_REQUEST_FAILURE = "WIDGET_REQUEST/FAILURE";

export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST";
export const LOAD_USER_REQUEST_SUCCESS = "LOAD_USER_REQUEST/SUCCESS";
export const LOAD_USER_REQUEST_FAILURE = "LOAD_USER_REQUEST/FAILURE";

export const GET_NOTIFICATIONS_REQUEST = "desion/notifications/request";
export const GET_NOTIFICATIONS_REQUEST_SUCCESS =
  "desion/notifications/request/success";
export const GET_NOTIFICATIONS_REQUEST_FAILURE =
  "desion/notifications/request/failure";

export const TOGGLE_SIDEBAR_LAYOUT = "desion/sidebar/request/toggle";
