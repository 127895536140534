import Request from "./request";
import Axios from "axios";
// import qs from "qs";
import axios from "axios";

const accessToken =
  "2be07f4c7a98dc61421864877f7c5f76972f85f5018393c4bc983b9038e033c6";
const apiURL = "https://api.us.onelogin.com/api/1/users";
// import FileDownload from 'js-file-download'
const FileDownload = require("js-file-download");
// import { start } from 'repl';
const oneLoginAxios = axios.create({
  baseURL: apiURL,
  headers: {
    Authorization: `Bearer${accessToken}`,
  },
});
Axios.defaults.withCredentials = true;
const postURL = `SION/pages/inc/post.jsp`;

export const login = ({ username, secret, token }) => {
  return Request.post(
    "token/",
    JSON.stringify({ username: username, secret: secret, mfacode: token }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const fetchUsers = () => {
  return oneLoginAxios.get();
};
export const logout = () => {
  return Request.clearToken();
};

export const getUser = () => {
  return Request.get("loggedinuser/");
};

export const getUserAvatar = (userid) => {
  return Request.get(`SION/pages/inc/getImage.jsp?id=${userid}`);
};

export const getApps = () => {
  return Request.get(`apps/`);
};
export const getAppDetails = (id) => {
  return Request.get(`app/${id}`);
};

export const getCounter = (type) => {
  return Request.get(`notificationscount/${type}`);
};

export const getDetailStats = (type) => {
  const url = type ? `detail/${type}` : "details/";
  return Request.get(url);
};

export const getDeviceDetailStats = (type) => {
  const url = `${type}/`;
  return Request.get(url);
};

export const getArchivedDevices = (type) => {
  const url = `archiveddevices/`;
  return Request.get(url);
};

export const getDeviceCount = () => {
  const url = `devicescount/`;
  return Request.get(url);
};

export const getUnknownDevices = () => {
  return Request.get("unknowndevices/");
};

// export const getAlert =(type)=> {
export const getTotalInflection = (type) => {
  return Request.get(`infectionscount/`);
};

export const getReportBy = (type) => {
  return Request.get(`SION/pages/stats/getReportData.jsp?reportkey=${type}`);
};

export const getTopUrls = () => {
  return getReportBy("topurls");
};

export const searchDevices = () => {
  return Request.get(
    "SION/pages/stats/getSparkData2.jsp?type=search%2Fdevices"
  );
};

export const listDevices = () => {
  return Request.get("devices/");
};

export const searchUsers = () => {
  return Request.get("users/");
};

export const searchUsersDept = () => {
  return Request.get("usersbydept/");
};

export const getUsername = (data) => {
  return Request.get(`user/${data.name}`);
};

export const getEmployee = (id) => {
  return Request.get(`users/${id}`);
};

export const getEmployeeHistory = (id) => {
  return Request.get(`history/${id}`);
};

export const getEmployeeStats = (id) => {
  return Request.get(`users/acc/${id}`);
};

export const getDeviceActivities = (deviceId) => {
  return Request.get(`search/srcmac=${deviceId.toLocaleLowerCase()}`);
};

export const getEmployeeAnalytics = (email) => {
  return Request.get(`azureusersignins/${email}`);
};

export const getRules = () => {
  return Request.get("rules/");
};

export const getTasks = () => {
  return Request.get("sch/");
};

export const getEmail = () => {
  return Request.get("templates/");
};

export const getAllReports = () => {
  return Request.get("reports/");
};

/* export const getReport = (reportId, format) => {
  return Request.get(
    `report/${reportId}/${format}`
  );
}; */

export const getReviews = () => {
  return Request.get("reviews/");
};
export const getSmtpConfig = () => {
  return Request.get("listsmtpconfig/");
};

export const getInfectionsReport = () => {
  return Request.get("generateResult/infections");
};

export const getComputersReport = () => {
  return Request.get("computersreport/");
};
export const getAppReport = (name) => {
  return Request.get("computersreport/");
};

export const getUserAppRules = (appId) => {
  return Request.get(`SION/pages/inc/getJSON.jsp?ep=app&id=${appId}`);
};

export const getAppPreview = (appId) => {
  return Request.get(`apps/${appId}/preview`);
};

export const getEntitlements = () => {
  return Request.get("adgroups/");
};

export const getTerminatedUserStats = () => {
  return Request.get("terminatedusers/");
};

export const getUserRoles = () => {
  return Request.get("showroles/");
};

export const getIsMFAEnabled = (username) => {
  return Request.get("/ismfaenabled/", {
    params: {
      username: username,
    },
  });
};

export const getUserRolesDetails = (id) => {
  return Request.get(`rolelogins/${id}`);
};

export const getReviewDetails = () => {
  return Request.get(`reviewdetails/`);
};

//user list with decisions/list of all the reviews for this
export const getMyReviews = (id) => {
  return Request.get(`reviewer/${id}`);
};

//all the people who can review
export const getAllReviews = () => {
  return Request.get(`reviewer/`);
};

export const getRiskScore = () => {
  return Request.get(`topriskscoreusers/`);
};
export const getLoggedUser = () => {
  return getUser();
};

export const getlastLogonInfo = () => {
  return Request.get(`lastlogon/`);
};

export const getAccountLockOut = () => {
  return Request.get("lockouts/");
};

export const getFailedLogin = () => {
  return Request.get("failedlogin/");
};

export const getFailedSionlogin = () => {
  return Request.get("unsuccessfulloginattempts/");
};

export const getServiceAccounts = () => {
  return Request.get("servicegroups/");
};

export const getTopNotifications = () => {
  return Request.get(`notificationstatistics/susp`);
};
export const getTopAlerts = () => {
  return Request.get(`notificationstatistics/alert`);
};

export const getIpLocation = (ip) => {
  return Request.get(`geoip/${ip}`);
};

export const renewToken = () => {
  return Request.get("token/");
};

export const getOneLoginUsers = () => {
  return Request.get("oneloginusers/");
};

export const getSuspiciousIp = () => {
  return Request.get("suspiciousip/");
};

export const getOneLoginGroups = () => {
  return Request.get("onelogingroups/");
};

export const getUserApps = (userId) => {
  return Request.get(`oneloginapps/${userId}`);
};

export const getAzureSignIn = () => {
  return Request.get(`azuresignins/`);
};

export const getAdminSignIn = () => {
  return Request.get(`adminlogins/`);
};

export const getMfaCheck = () => {
  return Request.get("checkmfa/");
};

export const getWidgetLayout = (user) => {
  return Request.get(`getWidgetLayout/${user.data.name}`);
};

export const getWidgets = () => {
  return Request.get(`widgetnames/`);
};

export const getKeywords = () => {
  return Request.get(`globalkeywords/`);
};

export const getLocalKeywords = () => {
  return Request.get(`keywords/`);
};

export const getMLWidgetData = (user) => {
  return Request.get(`analyzelogins/${user}`);
};

export const getTopURL = (time) => {
  return Request.get(`topurls/${time}`);
};

export const getMLAnomalies = () => {
  return Request.get(`getData`);
};

export const getJiraKey = () => {
  return Request.get("jiraproject/");
};

export const getAcknowledged = (flag) => {
  return Request.get(`ackthreat/${flag}`);
};

export const getTicketed = (type) => {
  return Request.get(`ticketed/${type}`);
};

export const getComputersUpdateReport = (id) => {
  return Request.get(`softwareupdates/${id}`);
};

export const getEmployeeAnalysis = (email) => {
  return Request.get(`getDataUsageReport/${email}`);
};

export const getO365users = () => {
  return Request.get(`O365Users/`);
};

export const getJiraDetails = (flag) => {
  return Request.get(`getjiraissue/${flag}`);
};

export const getDiscoveredDevice = (flag) => {
  return Request.get(`nmapscan/`);
};

export const fetchIp = () => {
  return Request.get(`fetchIpDetails/`);
};

export const fetchProfilePic = (id) => {
  return Request.get(`users/photo/${id}`);
};

export const ScheduleAccess = (reqData) => {
  return Request.post(
    `updateAccountExpiry/`,
    {
      data: reqData,
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const AddIp = (data, action) => {
  return Request.post(
    `actionIpDetails/${action}`,
    JSON.stringify({
      ipList: data,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const setFrequency = (data) => {
  return Request.post(
    `updateScanSettings/`,
    JSON.stringify({
      cron: data,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const pushComputersUpdate = (data, id) => {
  return Request.post(
    `pushsoftwareupdates/`,
    JSON.stringify({
      softwareUpdateIds: [data.id + ""],
      vendors: [data.vendor],
      software: [data.software],
      computerIds: [id + ""],
      status: "PushedUpdates",
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};
export const archiveDevice = (device) => {
  return Request.post(
    `addknowndevicetoarchives/`,
    JSON.stringify({
      deviceid: device.macaddress ? device.macaddress : device.devicename,
      devicename: device.devicename ? device.devicename : "unknown",
      devicetype: device.devicetype ? device.devicetype : "unknown",
      name: device.assignedto ? device.assignedto : "unknown",
      issuedate: device.created ? device.created : "unknown",
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const acknowledgeThreat = (deviceId, flag) => {
  return Request.post(
    `ackthreat/${deviceId}`,
    JSON.stringify({
      flag: flag,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

// TODO: Test new endpoint.
// Endpoint expects  array of logids and flag
// Update src/containers/Dashboard/UserAnomaly/Anomalies/IsolationModal.js
// Update src/containers/Dashboard/Details/AttackDescription.js
export const acknoledgeThreatNew = (deviceIds, flag) => {
  return Request.post(
    `ackthreat/`,
    JSON.stringify({
      logid: deviceIds,
      flag: flag,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );

}

export const addKeywords = (data) => {
  return Request.post(
    `addkeywords/`,
    JSON.stringify({
      keyword: data,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const addCustomKeywords = (data) => {
  return Request.post(
    `addAWSkeywords/`,
    JSON.stringify({
      keyword: data,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const blockIpAddress = (data) => {
  return Request.post(
    `blockaddress/`,
    JSON.stringify({
      name: "Defend_" + data.deviceid,
      subnet: data.deviceid + "/32",
      type: "ipmask",
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const createJiraTicketAnomalies = (data, comment) => {
  return Request.post(
    `createjiraticket/`,
    JSON.stringify({
      summary:
        "Anomalous Behaviour Detected on SiON from IP " + data.deviceid + ". ",
      descriptiontext:
        " We noticed the following activity: " +
        comment +
        ", " +
        "from user " +
        data.user +
        "Could you please investigate more and provide an update on this issue. The User's IP address: " +
        data.deviceid,

      issuetype: "Email request",
      deviceid: data.deviceid,
      name: data.name,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const createJiraTicketAlerts = (data, comment) => {
  return Request.post(
    `createjiraticket/`,
    JSON.stringify({
      summary:
        "Suspicious Activity Detected on SiON from IP " + data.deviceid + ". ",
      descriptiontext:
        "We noticed the following activity: " +
        data.name +
        ", " +
        data.description +
        " Could you please investigate more and provide an update on this issue.",
      issuetype: "Email request",
      deviceid: data.deviceid,
      name: data.name,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const removeKeywords = (data) => {
  return Request.post(
    `removekeywords/`,
    JSON.stringify({
      keyword: data,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

//find app id. Can't find the reference
export const addDevice = (appId, device) => {
  return Request.post(
    "https://sion.defendedge.io/addunknowntoknown/",
    JSON.stringify({
      name: device.deviceid,
      identityid: device.userid,
      data: {
        deviceid: device.deviceid,
        devicename: device.devicename,
        devicetype: device.devicetype,
        issuedate: device.issuedate,
        description: device.description,
      },
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const getMFA = () => {
  return Request.get(`mfaenable/`);
};

export const toggleITAccess = (data) => {
  return Request.post(
    `directory/${data.op}`,
    JSON.stringify({
      nativeid: data.distinguishedName,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const getPCAPTraffic = () => {
  return Request.get(`pcapevents/`);
};

export const searchTraffic = (data) => {
  return Request.post(
    `pcaptraffic/?id=${data.id}`,
    JSON.stringify({
      id: data.id,
      limit: data.limit,
    })
  );
};

export const pcapAnalysis = (id) => {
  return Request.get(`pcapmeta/?id=${id}`);
};

export const paginatedEvents = (data) => {
  return Request.post(
    `notifications/`,
    JSON.stringify({
      type: data.type,
      limit: data.limit,
      token: data.token,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const paginatedTraffic = (data) => {
  return Request.post(
    `pcaptraffic/?id=${data.id}`,
    JSON.stringify({
      id: data.id,
      limit: data.limit,
      token: data.token,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const revokeSignIn = (data) => {
  return Request.post(`revokesigninsession/${data}`, JSON.stringify(), {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  });
};

export const resetPassword = (data) => {
  return Request.post(
    `directory/resetPassword`,
    JSON.stringify({
      nativeid: data.distinguishedName,
      password: data.newPassword,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const saveLayout = (data, name) => {
  return Request.post(
    `updatewidgetlayout/${name}`,
    JSON.stringify({
      data: data,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const sendFeedback = (data) => {
  return Request.post("feedback/", JSON.stringify(data), {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  });
};

export const requestOrg = (fromemail, description) => {
  return Request.post(
    "requestorg/",
    JSON.stringify({
      requestor: fromemail,
      message: description,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const sendRule = (script, ruleID, name, desc, type) => {
  return Request.post(
    `${postURL}?action=rules/${ruleID}`,
    JSON.stringify({
      script: script,
      name: name,
      description: desc,
      type: type,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const sendReport = (reportID, type, parameters, parameterValue) => {
  const query = {};
  if (!!parameters) {
    if (!!parameters.name) {
      query[parameters.name] = `${parameterValue}`;
    }
  }
  return Request.post(
    `report/${reportID}/${type}`,
    JSON.stringify({
      query: JSON.stringify(query),
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  ).then((response) => {
    FileDownload(response.data, "report.csv");
    // if (type === "csv") {
    //   // window.open(response.data)
    //   FileDownload(response.data, "report.csv");
    // } else {
    //   // window.open(response.data)
    //   FileDownload(response.data, "report.csv");
    // }
  });
};
export const updateReview = (name, status, description, reviewer, reviewId) => {
  return Request.post(
    `review/${reviewId}`,
    JSON.stringify({
      name: name,
      description: description,
      status: status,
      reviewer: reviewer,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const sendReview = (
  name,
  status,
  startdate,
  description,
  reviewer,
  entitlement
) => {
  return Request.post(
    `createreview/`,
    JSON.stringify({
      name: name,
      description: description,
      status: status,
      startdate: startdate,
      reviewer: reviewer,
      entitlement: entitlement,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const sendTask = (taskID, name, start, desc, status) => {
  return Request.post(
    `sch/${taskID}`,
    JSON.stringify({
      name: name,
      starttime: start,
      description: desc,
      status: status,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const fortinetQurantine = (deviceid, flag) => {
  return Request.post(
    `quarantine/`,
    JSON.stringify({
      deviceid: deviceid,
      flag: flag,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const fsecureQuarantine = (computerid, status) => {
  return Request.post(
    `fsecureaccess/`,
    JSON.stringify({
      computerId: computerid,
      status: status,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const makeDecision = (
  id,
  reviewer,
  subject,
  entitlement,
  decision,
  reviewId,
  comments
) => {
  return Request.post(
    `updatereviewdetails/${id}`,
    JSON.stringify({
      //  reviewer:  reviewer,
      //  subject: subject,
      //  entitlement: entitlement,
      decision: decision,
      //  reviewId: reviewId,
      comments: comments,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

export const searchTimelines = (data = {}) => {
  return Request.post(
    "search/",
    JSON.stringify({
      startdate: data.startDate.toString(),
      enddate: data.endDate.toString(),
      pagesize: data.pageSize.toString(),
    })
  );
};

export const paginatedSearch = (next) => {
  return Request.get(`search/?next=${next}`);
};

export const searchAllLogs = (data = {}) => {
  const query = {};
  if (!!data.startDate) {
    query.startdate = `${data.startDate}`;
  }
  if (!!data.endDate) {
    query.enddate = `${data.endDate}`;
  }
  query.pagesize = `1000`;
  if (!!data.starttime) {
    query.starttime = `${data.starttime}`;
  }
  if (!!data.endtime) {
    query.endtime = `${data.endtime}`;
  }
  if (!!data.query) {
    query.keyword = `${data.query}`;
  }
  if (!!data.paging) {
    query.paging = `${data.paging}`;
  }
  return Request.post(
    "search/",
    JSON.stringify({
      startdate: data.startDate + "",
      enddate: data.endDate + "",
      pagesize: "1000",
    })
  );
};

export const getTimelinesCount = (data = {}) => {
  const query = {};
  if (!!data.startDate) {
    query.startdate = `${data.startDate}`;
  }
  if (!!data.endDate) {
    query.enddate = `${data.endDate}`;
  }
  return Request.post(
    `count/`,
    JSON.stringify({
      startdate: data.startDate + "",
      enddate: data.endDate + "",
    })
  );
};
